
import { NumberInput, coerceNumberProperty } from '@angular/cdk/coercion';
import { ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

export enum DropdownPositions {
  Auto = 'auto',
  Bottom = 'bottom',
  AutoBottom = 'autobottom',
  Right = ''
}

const DEFAULT_OFFSET_Y = 4;
const DEFAULT_OFFSET_X = 0;

@Component({
  selector: 'uikit-dropdown',
  exportAs: 'UIKitDropdown',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
      <ng-template cdkPortal cdk-scrollable>
          <ng-content></ng-content>
      </ng-template>`
})
export class UIKitDropdown implements OnInit, OnDestroy {

  @Input() autoWidth: boolean = false;
  @Input() reference: any;
  @Input() positions = DropdownPositions.Auto;
  @Input() panelClass: string | string[];
  @Input() backdropClass: string | string[];
  @Input() maxHeight: string;
  @Input() closeOnBackdropClick: boolean = true;
  @Input() withPush = false;
  @Input() handset: boolean = false;
  @Input() fullScreen: boolean = false;

  _offset: number = DEFAULT_OFFSET_Y;
  @Input()
  public set offset(value: NumberInput) {
    this._offset = coerceNumberProperty(value);
  }
  _offsetX: number = DEFAULT_OFFSET_X;
  @Input()
  public set offsetX(value: NumberInput) {
    this._offsetX = coerceNumberProperty(value);
  }
  @Input() hasBackdrop = true;
  @ViewChild(CdkPortal) contentTemplate: CdkPortal;

  public overlayRef: OverlayRef;

  @Output() visible: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() backdropClick: EventEmitter<void> = new EventEmitter<void>();

  private _showing = false;

  public get showing(): boolean {
    return this._showing;
  }
  public set showing(isShowing: boolean) {
    this._showing = isShowing;
    this.visible.emit(isShowing);
  }

  constructor(protected overlay: Overlay) {
  }

  ngOnInit() {
    this.createOverlay();
  }

  private createOverlay() {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.backdropClick().subscribe(_ => {
      if (this.closeOnBackdropClick) {
        this.hide();
      }
      this.backdropClick.emit();
    });
  }

  public toggle() {
    if (this.showing) {
      this.hide();
    } else {
      this.show();
    }
  }
  public show() {
    this.overlayRef.attach(this.contentTemplate);
    this.syncWidth();
    this.showing = true;
  }

  public hide() {
    this.overlayRef.detach();
    this.showing = false;
  }

  @HostListener('window:resize')
  public onWinResize() {
    this.syncWidth();
  }

  protected getOverlayConfig(): OverlayConfig {

    // We could use different positioning for mobile /w BreakpointObserver
    // const mobilePositionStrategy = this.overlay.position().global();
    // and also set differnt width / height in config like:
    // width: this.isSmallScreen ? '100%' : undefined,
    // height: this.isSmallScreen ? '100vh' : undefined

    const bottomPosition: ConnectionPositionPair = {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    };
    const topPosition: ConnectionPositionPair = {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    };
    const bottomEndPosition: ConnectionPositionPair = {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    };
    const rightPosition: ConnectionPositionPair = {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
    };
  
    let positionStrategy;
    if (this.fullScreen) {
      positionStrategy = this.overlay
        .position()
        .global()
        .top('0')
        .left('0');
    } else {
      const usedPositions: ConnectionPositionPair[] = [];
      if (this.positions === DropdownPositions.Auto) {
        usedPositions.push(bottomPosition);
        usedPositions.push(bottomEndPosition);
        usedPositions.push(topPosition);
      } else if (this.positions === DropdownPositions.AutoBottom) {
        usedPositions.push(bottomPosition);
        usedPositions.push(bottomEndPosition);
      } else if (this.positions === DropdownPositions.Right) {
        usedPositions.push(rightPosition);
      }

      positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(this.reference)
        .withPush(this.withPush)
        .withGrowAfterOpen(false)
        .withViewportMargin(this.handset ? 0 : 8)
        .withDefaultOffsetY(this._offset)
        .withDefaultOffsetX(this._offsetX)
        .withPositions(usedPositions);
    }
  
    const scrollStrategy = this.overlay.scrollStrategies.block();
  
    return new OverlayConfig({
      positionStrategy: positionStrategy,
      scrollStrategy: scrollStrategy,
      hasBackdrop: this.hasBackdrop,
      panelClass: this.fullScreen
        ? 'fullscreen-overlay'
        : this.panelClass ?? '',
      backdropClass: this.backdropClass ?? 'cdk-overlay-transparent-backdrop',
      maxHeight: this.fullScreen ? '100vh' : this.maxHeight ?? '450px',
      maxWidth: this.fullScreen ? '100vw' : '1024px',
      width: this.fullScreen ? '100%' : this.handset ? '100%' : '',
    });
  }
  

  private syncWidth() {
    if (!this.overlayRef || this.autoWidth) {
      return;
    }
    const refRect = this.reference.getBoundingClientRect();
    this.overlayRef.updateSize({ width: refRect.width });
  }

  ngOnDestroy(): void {
    if (this.overlayRef?.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}
